// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SortableList_SortableList__dmrWn {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
  list-style: none;
}`, "",{"version":3,"sources":["webpack://./src/styles/SortableList.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,UAAA;EACA,gBAAA;AACF","sourcesContent":[".SortableList {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  padding: 0;\n  list-style: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SortableList": `SortableList_SortableList__dmrWn`
};
export default ___CSS_LOADER_EXPORT___;
