// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SortableItem_SortableItem__1Yo8O {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  padding: 0px 8px;
  box-sizing: border-box;
  list-style: none;
  color: #333;
  font-weight: 400;
  /* font-size: 1rem; */
  font-family: sans-serif;
  width: 100%;
}

.SortableItem_DragHandle__vO\\+JS {
  display: flex;
  width: 12px;
  padding: 15px;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  touch-action: none;
  cursor: var(--cursor, pointer);
  border-radius: 5px;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}

.SortableItem_DragHandle__vO\\+JS:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.SortableItem_DragHandle__vO\\+JS:focus-visible {
  box-shadow: 0 0px 0px 2px #4c9ffe;
}

.SortableItem_DragHandle__vO\\+JS svg {
  flex: 0 0 auto;
  margin: auto;
  height: 100%;
  overflow: visible;
  fill: #919eab;
}`, "",{"version":3,"sources":["webpack://./src/styles/SortableItem.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EAIA,sBAAA;EACA,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,qBAAA;EACA,uBAAA;EACA,WAAA;AAFF;;AAKA;EACE,aAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,kBAAA;EACA,8BAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,6BAAA;EACA,wCAAA;AAFF;;AAKA;EACE,qCAAA;AAFF;;AAKA;EACE,iCAAA;AAFF;;AAKA;EACE,cAAA;EACA,YAAA;EACA,YAAA;EACA,iBAAA;EACA,aAAA;AAFF","sourcesContent":[".SortableItem {\n  display: flex;\n  justify-content: space-between;\n  flex-grow: 1;\n  align-items: center;\n  padding: 0px 8px;\n  // background-color: #fff;\n  // box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05), 0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);\n  // border-radius: calc(4px / var(--scale-x, 1));\n  box-sizing: border-box;\n  list-style: none;\n  color: #333;\n  font-weight: 400;\n  /* font-size: 1rem; */\n  font-family: sans-serif;\n  width: 100%;\n}\n\n.DragHandle {\n  display: flex;\n  width: 12px;\n  padding: 15px;\n  align-items: center;\n  justify-content: center;\n  flex: 0 0 auto;\n  touch-action: none;\n  cursor: var(--cursor, pointer);\n  border-radius: 5px;\n  border: none;\n  outline: none;\n  appearance: none;\n  background-color: transparent;\n  -webkit-tap-highlight-color: transparent;\n}\n\n.DragHandle:hover {\n  background-color: rgba(0, 0, 0, 0.05);\n}\n\n.DragHandle:focus-visible {\n  box-shadow: 0 0px 0px 2px #4c9ffe;\n}\n\n.DragHandle svg {\n  flex: 0 0 auto;\n  margin: auto;\n  height: 100%;\n  overflow: visible;\n  fill: #919eab;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SortableItem": `SortableItem_SortableItem__1Yo8O`,
	"DragHandle": `SortableItem_DragHandle__vO+JS`
};
export default ___CSS_LOADER_EXPORT___;
