// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PDFViewerModal.css */
.PDFViewer_pdf-viewer-modal__6bx2R {
  width: 100%; /* Set the width to 100% */
  max-width: 100%; /* Ensure it doesn't exceed the container width */
}

.PDFViewer_pdf-pages__o-Y-Y {
  display: flex;
}

/* Add any additional styling as needed */`, "",{"version":3,"sources":["webpack://./src/styles/PDFViewer.module.scss"],"names":[],"mappings":"AAAA,uBAAA;AACA;EACE,WAAA,EAAA,0BAAA;EACA,eAAA,EAAA,iDAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA,yCAAA","sourcesContent":["/* PDFViewerModal.css */\n.pdf-viewer-modal {\n  width: 100%; /* Set the width to 100% */\n  max-width: 100%; /* Ensure it doesn't exceed the container width */\n}\n\n.pdf-pages {\n  display: flex;\n}\n\n/* Add any additional styling as needed */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pdf-viewer-modal": `PDFViewer_pdf-viewer-modal__6bx2R`,
	"pdf-pages": `PDFViewer_pdf-pages__o-Y-Y`
};
export default ___CSS_LOADER_EXPORT___;
