// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Press_press__idP0L {
  min-height: 84vh;
}

.Press_press-image__-DX\\+t {
  height: 24rem !important;
  width: 100%;
  object-fit: cover;
  border: 1px solid;
  border: 1px solid black;
}`, "",{"version":3,"sources":["webpack://./src/styles/Press.module.scss"],"names":[],"mappings":"AAIA;EAEE,gBAAA;AAJF;;AAwBA;EACE,wBAAA;EACA,WAAA;EACA,iBAAA;EACA,iBAAA;EAEA,uBAAA;AAtBF","sourcesContent":["@import \"bootstrap/scss/functions\";\n@import \"bootstrap/scss/variables\";\n@import \"bootstrap/scss/mixins\";\n\n.press {\n  // border: 1px solid white;\n  min-height: 84vh;\n}\n\n// .page-curve {\n//   min-height: 86vh;\n//   background-image: url(\"../../public/page_curve_white.png\");\n//   background-repeat: no-repeat;\n//   background-size: 12%;\n//   background-position-x: 100.6%;\n//   background-position-y: 101.6%;\n//   background-blend-mode: multiply;\n//   background-clip: padding-box;\n\n//   @include media-breakpoint-down(md) {\n//     background-size: 28%;\n//     background-position-x: 101%;\n//     background-position-y: 101%;\n//   }\n// }\n\n.press-image {\n  height: 24rem !important;\n  width: 100%;\n  object-fit: cover;\n  border: 1px solid;\n  // object-fit: cover;\n  border: 1px solid black;\n\n  @include media-breakpoint-up(md) {\n    // max-height: 55vh;\n    // max-height: 18rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"press": `Press_press__idP0L`,
	"press-image": `Press_press-image__-DX+t`
};
export default ___CSS_LOADER_EXPORT___;
