// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MultiSelectAnswer_multi-select-buttons-group__n1NzJ .MultiSelectAnswer_select-button__DQLjc {
  position: relative;
}
.MultiSelectAnswer_multi-select-buttons-group__n1NzJ .MultiSelectAnswer_select-button__DQLjc img {
  max-width: 12rem;
}
.MultiSelectAnswer_multi-select-buttons-group__n1NzJ .MultiSelectAnswer_select-button__DQLjc label {
  border: 1px solid black;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  padding: 4px;
  width: auto;
  cursor: pointer;
}
.MultiSelectAnswer_multi-select-buttons-group__n1NzJ .MultiSelectAnswer_select-button__DQLjc input:checked + label {
  color: rgb(255, 255, 255);
  background-color: #000000;
}
.MultiSelectAnswer_multi-select-buttons-group__n1NzJ .MultiSelectAnswer_select-button__DQLjc input:checked + label:hover, .MultiSelectAnswer_multi-select-buttons-group__n1NzJ .MultiSelectAnswer_select-button__DQLjc input:checked + label:focus, .MultiSelectAnswer_multi-select-buttons-group__n1NzJ .MultiSelectAnswer_select-button__DQLjc input:checked + label:active {
  background-color: #000000;
  color: rgb(255, 255, 255);
}
.MultiSelectAnswer_multi-select-buttons-group__n1NzJ .MultiSelectAnswer_select-button__DQLjc input {
  opacity: 0;
  position: absolute;
}
.MultiSelectAnswer_multi-select-buttons-group__n1NzJ .MultiSelectAnswer_select-button__DQLjc .MultiSelectAnswer_button-select__HVn7w {
  margin: 10px 10px 0;
  display: flex;
  background-color: transparent;
  color: rgb(0, 0, 0);
}
.MultiSelectAnswer_multi-select-buttons-group__n1NzJ .MultiSelectAnswer_select-button__DQLjc .MultiSelectAnswer_button-select__HVn7w:hover, .MultiSelectAnswer_multi-select-buttons-group__n1NzJ .MultiSelectAnswer_select-button__DQLjc .MultiSelectAnswer_button-select__HVn7w:focus, .MultiSelectAnswer_multi-select-buttons-group__n1NzJ .MultiSelectAnswer_select-button__DQLjc .MultiSelectAnswer_button-select__HVn7w:active {
  color: rgb(0, 0, 0);
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/styles/MultiSelectAnswer.module.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;AAEI;EACE,gBAAA;AAAN;AAGI;EACE,uBAAA;EACA,kBAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;AADN;AAII;EACE,yBAAA;EACA,yBAAA;AAFN;AAGM;EAGE,yBAAA;EACA,yBAAA;AAHR;AAOI;EACE,UAAA;EACA,kBAAA;AALN;AAQI;EACE,mBAAA;EACA,aAAA;EACA,6BAAA;EACA,mBAAA;AANN;AAQM;EAGE,mBAAA;EACA,6BAAA;AARR","sourcesContent":[".multi-select-buttons-group {\n  .select-button {\n    position: relative;\n\n    img {\n      max-width: 12rem;\n    }\n\n    label {\n      border: 1px solid black;\n      text-align: center;\n      justify-content: center;\n      align-items: center;\n      border-radius: 24px;\n      padding: 4px;\n      width: auto;\n      cursor: pointer;\n    }\n\n    input:checked + label {\n      color: rgb(255, 255, 255);\n      background-color: #000000;\n      &:hover,\n      &:focus,\n      &:active {\n        background-color: #000000;\n        color: rgb(255, 255, 255);\n      }\n    }\n\n    input {\n      opacity: 0;\n      position: absolute;\n    }\n\n    .button-select {\n      margin: 10px 10px 0;\n      display: flex;\n      background-color: transparent;\n      color: rgb(0, 0, 0);\n\n      &:hover,\n      &:focus,\n      &:active {\n        color: rgb(0, 0, 0);\n        background-color: transparent;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"multi-select-buttons-group": `MultiSelectAnswer_multi-select-buttons-group__n1NzJ`,
	"select-button": `MultiSelectAnswer_select-button__DQLjc`,
	"button-select": `MultiSelectAnswer_button-select__HVn7w`
};
export default ___CSS_LOADER_EXPORT___;
