// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MultiSelectOrderedAnswer_btn__o9PPX {
  border: 1px solid black;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  cursor: pointer;
  background-color: #f7f7f7;
  line-height: normal;
}

.MultiSelectOrderedAnswer_multi-select-buttons-group__GPwKc .MultiSelectOrderedAnswer_select-button__y1o0g {
  position: relative;
}
.MultiSelectOrderedAnswer_multi-select-buttons-group__GPwKc .MultiSelectOrderedAnswer_select-button__y1o0g label {
  border: 1px solid black;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  padding: 4px;
  width: 22rem;
  cursor: pointer;
}
.MultiSelectOrderedAnswer_multi-select-buttons-group__GPwKc .MultiSelectOrderedAnswer_select-button__y1o0g input:checked + label {
  color: rgb(255, 255, 255);
  background-color: #000000;
}
.MultiSelectOrderedAnswer_multi-select-buttons-group__GPwKc .MultiSelectOrderedAnswer_select-button__y1o0g input:checked + label:hover, .MultiSelectOrderedAnswer_multi-select-buttons-group__GPwKc .MultiSelectOrderedAnswer_select-button__y1o0g input:checked + label:focus, .MultiSelectOrderedAnswer_multi-select-buttons-group__GPwKc .MultiSelectOrderedAnswer_select-button__y1o0g input:checked + label:active {
  background-color: #000000;
  color: rgb(255, 255, 255);
}
.MultiSelectOrderedAnswer_multi-select-buttons-group__GPwKc .MultiSelectOrderedAnswer_select-button__y1o0g input {
  opacity: 0;
  position: absolute;
}
.MultiSelectOrderedAnswer_multi-select-buttons-group__GPwKc .MultiSelectOrderedAnswer_select-button__y1o0g .MultiSelectOrderedAnswer_button-select__jKHYD {
  margin: 0 10px 10px 0;
  display: flex;
  background-color: transparent;
  color: rgb(0, 0, 0);
}
.MultiSelectOrderedAnswer_multi-select-buttons-group__GPwKc .MultiSelectOrderedAnswer_select-button__y1o0g .MultiSelectOrderedAnswer_button-select__jKHYD:hover, .MultiSelectOrderedAnswer_multi-select-buttons-group__GPwKc .MultiSelectOrderedAnswer_select-button__y1o0g .MultiSelectOrderedAnswer_button-select__jKHYD:focus, .MultiSelectOrderedAnswer_multi-select-buttons-group__GPwKc .MultiSelectOrderedAnswer_select-button__y1o0g .MultiSelectOrderedAnswer_button-select__jKHYD:active {
  color: rgb(0, 0, 0);
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/styles/MultiSelectOrderedAnswer.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,kBAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EAGA,eAAA;EACA,yBAAA;EACA,mBAAA;AADF;;AAKE;EACE,kBAAA;AAFJ;AAII;EACE,uBAAA;EACA,kBAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;AAFN;AAKI;EACE,yBAAA;EACA,yBAAA;AAHN;AAIM;EAGE,yBAAA;EACA,yBAAA;AAJR;AAQI;EACE,UAAA;EACA,kBAAA;AANN;AASI;EACE,qBAAA;EACA,aAAA;EACA,6BAAA;EACA,mBAAA;AAPN;AASM;EAGE,mBAAA;EACA,6BAAA;AATR","sourcesContent":[".btn {\n  border: 1px solid black;\n  text-align: center;\n  justify-content: center;\n  align-items: center;\n  border-radius: 24px;\n  // padding: 8px;\n  // width: 5rem;\n  cursor: pointer;\n  background-color: #f7f7f7;\n  line-height: normal;\n}\n\n.multi-select-buttons-group {\n  .select-button {\n    position: relative;\n\n    label {\n      border: 1px solid black;\n      text-align: center;\n      justify-content: center;\n      align-items: center;\n      border-radius: 24px;\n      padding: 4px;\n      width: 22rem;\n      cursor: pointer;\n    }\n\n    input:checked + label {\n      color: rgb(255, 255, 255);\n      background-color: #000000;\n      &:hover,\n      &:focus,\n      &:active {\n        background-color: #000000;\n        color: rgb(255, 255, 255);\n      }\n    }\n\n    input {\n      opacity: 0;\n      position: absolute;\n    }\n\n    .button-select {\n      margin: 0 10px 10px 0;\n      display: flex;\n      background-color: transparent;\n      color: rgb(0, 0, 0);\n\n      &:hover,\n      &:focus,\n      &:active {\n        color: rgb(0, 0, 0);\n        background-color: transparent;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `MultiSelectOrderedAnswer_btn__o9PPX`,
	"multi-select-buttons-group": `MultiSelectOrderedAnswer_multi-select-buttons-group__GPwKc`,
	"select-button": `MultiSelectOrderedAnswer_select-button__y1o0g`,
	"button-select": `MultiSelectOrderedAnswer_button-select__jKHYD`
};
export default ___CSS_LOADER_EXPORT___;
